import React, { useEffect, useState } from "react";
import "./app.scss";
import { auth } from "./config/firebase";
import Login from "./component/Login";
import { getUserDetail } from "./component/LoginForm";
function App() {
  return (
    <div className="app">
      <iframe src={`https://dev-at-pos.web.app/kitchen-screen/123`} />
    </div>
  );
}

export default App;
